<template lang="pug">
  v-container
    v-row
      v-col
        .details.mb-10
          v-layout(
            align-center
          )
            h1.text-h4 {{ mso.name }}
            v-col.d-flex
              .actions.ml-auto
                v-tooltip(top)
                  template(#activator="{ on, attrs}")
                    v-btn.px-0.mr-2.my-1(
                      v-on="on"
                      min-width="40px"
                      @click="deleteMso"
                      color="error"
                    )
                      v-icon mdi-delete
                  span Delete MSO
                v-btn.ml-auto(
                  :to="{ name: 'edit-msos', params: { msoId: mso.id }}"
                  color="primary"
                )
                  v-icon(
                    left
                  ) mdi-pencil
                  | Edit MSO

        v-card.mb-16(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              h3.grey--text.text--darken-2 Shops
              v-spacer
              v-text-field.flex-shrink-1.flex-grow-0(
                v-model="searchShops"
                label="Search Shops"
                single-line
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                rounded
                dense
              )
          ShopsTable(
            :search="searchShops"
            :query="{ mso_id: msoId }"
            :msoId="msoId"
          )

        v-card.mb-16(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              h3.grey--text.text--darken-2 Users
              v-spacer
              v-text-field.flex-shrink-1.flex-grow-0(
                v-model="searchUsers"
                label="Search Users"
                single-line
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                rounded
                dense
              )
          UsersTable(
            :query="{ mso_id: msoId }"
            :msoId="msoId"
            :search="searchUsers"
          )
</template>

<script>
import { useGet } from 'feathers-vuex'
import UsersTable from '@/components/tables/UsersTable'
import ShopsTable from '@/components/tables/ShopsTable'

export default {
  name: 'Mso',
  props: {
    msoId: {
      type: String,
      required: true
    }
  },
  components: {
    UsersTable,
    ShopsTable
  },
  setup (props, context) {
    const { Msos } = context.root.$FeathersVuex.api

    // Get the MSO record
    const { item: mso, hasLoaded } = useGet({
      model: Msos,
      id: props.msoId
    })

    return {
      mso,
      hasLoaded
    }
  },
  methods: {
    async deleteMso () {
      try {
        const message = 'Are you sure you want to delete this MSO?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        if (isYes) {
          await this.mso.remove()
          this.$snackSuccess('MSO Deleted')
          this.$router.push({ name: 'msos' })
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  },
  data: () => ({
    searchShops: null,
    searchUsers: null
  })
}
</script>
